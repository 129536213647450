.head-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: -29px;
  width: 100%;
  left: 0;
}
.news-head {
  background-image: url("../../../../../public/images/Lbl_Skillbook-ListItem.svg");
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: black;
}

.news-head p {
  text-shadow: -2px 2px 2px #000, 2px 3px 2px #000, 2px -2px 0 #000,
    -2px -2px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.news-container {
  background-color: #04295e;
  position: relative;
  margin-top: 2rem;
  padding: 25px;
  border-radius: 1px;
  box-shadow: 8px 8px 0px 0px black;
  border: 3px solid black;
  position: relative;
}

.news-slide {
  color: rgb(16, 19, 20);
  padding: 10px 15px;
  font-family: "Lato", sans-serif !important;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.9rem;
}

.news-slide p {
  font-family: "Lato", sans-serif !important;
}

.slick-track {
  display: flex;
  align-items: center;
  background: white;
}
