.mobile-header {
  padding: 5px 20px;
  width: 100%;
  background: url("../../../../public/images/blue-bg.png");
  background-position: bottom center;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-logo-container {
  width: 70px;
  /* border: 2px solid white; */
}
.mobile-menu-icon-container {
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
}
.menu-text {
  color: #fff;
  text-transform: uppercase;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  font-family: "Brady Bunch Remastered";
  font-size: 40px;
  letter-spacing: 2px;
}

.mobile-logo {
  width: 100%;
}

.hamburger-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bars-icon {
  color: #fff;
  font-size: 24px;
  width: 50px;
  height: 50px;
  background: #ff8500;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: -2px 2px 2px #000;
}
.mobile-logo-container {
  width: 70px;
}
.mobile-logo-container img {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .mobile-header {
    display: none;
  }
}
