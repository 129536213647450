.blogDetail-container {
  background-image: url("../../../../../public/images/pages-bg.jpg");
  /* background: #00a0c7; */
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
}

.back-wrap {
  width: 80%;
  margin: auto;
  text-align: left;
}

.back-btn {
  display: block;
  padding-top: 50px;
  transition: transform 0.5s ease-out;
}

.back-btn:hover {
  transform: translateX(-20%);
}

.blogDetail-main-img {
  width: 80%;
  margin: auto;
  display: block;
  padding-top: 50px;
}

.blogDetail-title {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: 60px;
  text-transform: uppercase;
  padding-top: 30px;
  text-align: center;
  letter-spacing: 3px;
}

.blog-meta-container {
  width: 100%;
  text-align: center;
}

.blog-meta-container span {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: 20px;
  text-transform: capitalize;
  padding: 0px 5px;
  text-align: center;
  letter-spacing: 3px;
  /* border-right: 2px solid #000; */
}

.blogDetail-content-container {
  padding: 30px;
  width: 90%;
  margin: 20px auto;
  background: #fff;
  color: #000;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center;
  border-radius: 1px;
}

.blogDetail-content-container p {
  padding: 15px 0px;
}
.blogDetail-content-container * {
  font-family: Arial, Helvetica, sans-serif;
}

.blogDetail-content-container h2 {
  font-weight: bold;
}

.blogComment-container {
  width: 90%;
  background: #0076a3;
  padding: 30px;
  margin: 30px auto;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blogDetail-content-container figure {
  text-align: center;
}

.commentfield {
  @apply border-2 border-black;
  border-radius: 3px;
  border: 0;
  width: 100%;
  padding: 10px 20px;
  font-size: 17px;
  color: black;
}

.addComment-btn {
  background: #17a2b8;
  border-radius: 3px;
  border: 0;
  color: #fff;
  font-family: Arial;
  font-size: 17px;
  margin-top: 10px;
  padding: 10px 20px;
}

.commentsCount {
  font-family: Arial;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
  margin-bottom: 20px;
}

.commentList-container {
  @apply space-y-3 rounded;
  width: 100%;
  background: #fff;
}

.commentDetail-container {
  width: 100%;
  background: #eef7ff;
  padding: 20px;
  display: flex;

  /* justify-content: space-between; */
}

.commentor-imgContainer {
  width: 70px;
  height: 70px;
  border: 2px solid #17a2b8;
  border-radius: 100px;
  background: #fff;
  /* padding: ; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
}

.comment-time {
  color: #17a2b8;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.commentData {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.commenter-info {
  width: 120px;
}

.commentor-name {
  color: #17a2b8;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: bold;
  font-size: larger;
}

.actual-comment {
  /* overflow: auto; */
  color: #17a2b8;
  margin-top: 5px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 15px;
}

.videoIframe {
  width: 70% !important;
}

.commentForm {
  margin-top: 10px;
}

.pending-comment {
  background-color: hsl(0deg 0% 0% / 5%);
}

.comment {
  padding: 20px;
}

.blog_txt_container img {
  display: inline;
}

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .blogDetail-container {
    margin: 0 !important;
  }

  .blogDetail-main-img {
    width: 90%;
  }

  .commentDetail-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .commentData {
    align-items: center;
    margin-left: 0;
    margin-top: 10px;
  }

  .actual-comment {
    text-align: center;
  }

  .videoIframe {
    width: 100% !important;
  }
}

/* @media screen and (max-width: 1100px) {
  .blogDetail-container {
    margin-left: 245px;
  }
} */

@media screen and (max-width: 1024px) {
  .blogDetail-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .blogDetail-container {
    margin-left: 315px;
  }

  .blogDetail-content-container {
    width: 70%;
  }

  .blogComment-container {
    width: 70%;
  }

  .blogDetail-main-img {
    width: 70%;
  }
}

@media screen and (min-width: 1700px) {
  .blog_txt_container {
    width: 990px;
    margin: 0 auto;
  }
}
