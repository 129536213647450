.components-container-portal {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .components-container-portal {
    /* flex-direction: column; */
  height: 100%;

  }
}

.blogDetail-content-container h2 {
  font-size: 2rem;
}

.blogDetail-content-container p {
  font-size: 1.3rem;
  padding: 0px;
  padding-bottom: 5px;
}
