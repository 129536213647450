.slider-container {
  width: 100%;
  height: 200px;
  background: #000;
}

.blog-container {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.blog-box {
  width: 95% !important;
  height: 11.6rem;
  background-color: #000;
  display: flex;
  margin: 0 auto;
  z-index: 5;
  position: relative;
}

.left-arrow-container {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -25px;
  top: 0;
  z-index: 0;
}

.right-arrow-container {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -25px;
  top: 0;
}

.blog-img {
  padding: 3px 3px 2px 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;

  z-index: 6;
}

.blog-content-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 15px;
}

.blog-title {
  font-family: "Brady Bunch Remastered";
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #ffb400;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
}

.blog-date {
  font-size: 16px;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  text-transform: uppercase;
  font-weight: normal;
}

.swiper-button-next,
.swiper-button-prev {
  height: 40px !important;
  width: 40px !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}
