.blogList-container {
  background-image: url("../../../../public/images/pages-bg.jpg");
  /* background: #00a0c7; */
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
  @apply px-8;
}

.blog-cards-container {
  @apply flex flex-col w-full px-5;
}

.blogcard {
  @apply w-full grid grid-cols-6;
  border-bottom: 3px solid black;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.blog-img-container {
  @apply col-span-6 pr-0 lg:pr-7 lg:col-span-2;
}

.blog-detail-container {
  @apply col-span-6 sm:col-span-4;
}

.blog-listing-thumbnail {
  max-width: 100%;
  height: auto;
}

.blogListing-title {
  font-family: "Brady Bunch Remastered";
  font-size: 43px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #ffb400;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000;
  line-height: 1.2;
}
.blog-description {
  padding-top: 15px;
}
.blog-description {
  font-family: "Arial";
  font-size: 1rem;
  line-height: 1.5;
}
.blog-pagintaion {
  width: 90%;
  /* background: #000; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 60px auto;
}

.blog-left-arrow {
  margin-right: 15px;
}

.blog-right-arrow {
  margin-left: 15px;
}

.blogtitle-link {
  text-decoration: none;
}
