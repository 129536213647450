.nav-bar {
  /* border: 2px solid red; */
  /* min-height: 250px; */
}

.nav-links-container {
  display: flex;
  flex-direction: column;
}

li a {
  font-family: "Brady Bunch Remastered";
  color: #fff;
  font-size: 20px;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  margin-bottom: 2px;
  text-decoration: none;
  transition: all 0.8s;
}

li a:hover,
.active {
  color: #ffb400;
}

/* Responsiveness */

@media screen and (max-width: 1100px) {
  .nav-links-container li {
    margin-bottom: 3px;
  }
}

@media screen and (min-width: 1440px) {
  li a {
    margin: 7px 0px;
    font-size: 27px;
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  li a {
    font-size: 30px !important;
    margin: 0 !important;
  }
  .meet-heroes-img {
    margin: auto;
  }
}

@media screen and (min-width: 1700px) {
  .nav-NavLinks-container {
    margin-bottom: 20px;
  }
  /* li {
        margin: 10px 0px;
    } */

  li a {
    margin: 10px 0px;
    font-size: 30px;
  }
}
