.seasonPs-container {
  background-image: url("../../../../public/images/comics-bg.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: hidden;
}

.seasonPs-content-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
}

.seasonPs-sidebar {
  width: 25%;
  height: 85vh;
  background-color: #e4e4e4a2;
  margin: 0 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.seasonPs-main {
  width: 70%;
  min-height: 100%;
  background: #00a0c7;
  border: 5px solid #fff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.season-title {
  font-family: "Brady Bunch Remastered";
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: white;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  word-spacing: 2.5px;
}

.seasonPs-desc-container {
  width: 100%;
  height: 100%;
  /* background-color: #00a0c7; */
  margin-top: 10px;
  overflow: auto;
}

.seasonPs-descrp {
  font-size: 17px;
  font-family: "Acme";
  color: #000;
  font-weight: 400;
  line-height: 30px;
  margin: 15px 0px;
}

/* Season Pass Main content Style */
.seasonPs-newTag {
  width: 100px;
  height: 40px;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #18ff00;
  font-family: "Acme";
  font-size: 25px;
  text-transform: uppercase;
  transform: rotate(-10deg);
  margin-left: -5px;
  left: 0;
  top: -40px;
  font-weight: 400;
  position: absolute;
}

.activeSeasonPs-data {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.active-seasonPs-title {
  font-size: 20px;
  font-family: "Acme";
  text-transform: uppercase;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  margin-right: 20px;
  /* background-color: #18ff00; */
  max-width: 350px;
  text-align: center;
}

.seasonPs-date {
  font-size: 18px;
  font-family: "Acme";
  text-transform: capitalize;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  margin-right: 20px;
  min-width: 100px;
}

.seasonPs-main-img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.first-slide {
  margin-top: 5px;
}

.latestSeasonStories-container {
  width: 100%;
  display: flex;
  padding: 10px;

  justify-content: space-between;
}

.seasonItme-img {
  width: 230px;
}

.readMore-seasonStories-btn {
  margin-left: auto;
}

.latestSeasonPs-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background: #000; */
}

.seasonPs-item-title {
  font-size: 13px;
  font-family: "Acme";
  text-transform: uppercase;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  min-width: 100px;
}

.seasonPs-item-date {
  font-size: 13px;
  font-family: "Acme";
  text-transform: capitalize;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}

.modal {
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 70%;
  background-color: white;
  position: relative;
}

.hide_modal {
  display: none;
}

.modal_container {
  margin: 0 auto;
  left: 50%;
  top: 50%;

  /** Position should be absolute */
  position: absolute;
  /** And box must have a width, any width */
  width: 40%;
  background: #faebd7;
  text-align: center;
  padding: 10px;
  transform: translate(-50%, -50%);

  /* Animation */
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.modalClose {
  font-size: 30px;
}

.modal_close_span {
  width: 25px;
  height: 25px;
  margin-left: auto;
  background: #fff;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: -5px;
  left: 5px;
  border: 1px solid #fff;
  position: relative;
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    /* left: 0%; */
    -webkit-transform: translate(60%, 0px);
    transform: translate(60%, 0px);
  }

  100% {
    /* left: 60%; */
    -webkit-transform: translate(60%, 100px);
    transform: translate(60%, 100px);
  }
}

@keyframes slide-top {
  0% {
    /* left: 0%; */
    -webkit-transform: translate(-60%, 0px);
    transform: translate(60%, 0px);
  }

  100% {
    /* left: 60%; */
    -webkit-transform: translate(60%, 100px);
    transform: translate(60%, 100px);
  }
}

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .seasonPs-container {
    margin: 0 !important;
  }

  .seasonPs-sidebar {
    width: 90%;
    margin-bottom: 10px;
    height: auto;
    margin: 23px 10px 52px 40px;
  }

  .latestSeasonStories-container {
    flex-direction: column;
  }

  .seasonPs-content-container {
    flex-direction: column;
  }

  .seasonPs-main {
    width: 90%;
    margin: 0 auto;
  }

  .seasonPs-main-img {
    width: 100%;
  }

  .seasonItme-img {
    width: 100%;
    margin: 10px 0px;
  }

  .activeSeasonPs-data {
    flex-direction: column;
    cursor: pointer;
  }

  .modal {
    width: 100%;
    /* border: 2px solid black; */
    min-height: 100vh;
    /* left: -50%; */
  }

  .modal_container {
    width: 90%;
    left: -50%;
  }
}

/* @media screen and (max-width: 1100px) {
  .seasonPs-container {
    margin-left: 245px;
  }
} */
@media screen and (max-width: 1024px) {
  .seasonPs-container {
    margin-left: 0px;
  }
}

@media screen and (min-width: 1440px) {
  .seasonPs-container {
    margin-left: 315px;
  }
}

@media screen and (min-width: 1700px) {
  .seasonPs-sidebar {
    width: 20%;
  }

  .seasonPs-descrp {
    font-size: 18px;
  }

  .activeSeasonPs-data {
    justify-content: flex-start;
    margin-top: 20px;
  }

  /* Animation keyfram */

  @-webkit-keyframes slide-top {
    0% {
      /* left: 0%; */
      -webkit-transform: translate(60%, 0px);
      transform: translate(60%, 0px);
    }

    100% {
      /* left: 60%; */
      -webkit-transform: translate(60%, 300px);
      transform: translate(60%, 300px);
    }
  }

  @keyframes slide-top {
    0% {
      /* left: 0%; */
      -webkit-transform: translate(-60%, 0px);
      transform: translate(60%, 0px);
    }

    100% {
      /* left: 60%; */
      -webkit-transform: translate(60%, 300px);
      transform: translate(60%, 300px);
    }
  }
}
