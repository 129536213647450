.meetHeroes-container {
  background-image: url("../../../../public/images/pages-bg.jpg");
  /* background: #00a0c7; */
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
}

.page-title {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  font-size: 40px;
  text-transform: uppercase;
  padding: 20px 30px;
}

.meetHeroes-main-img {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.heroes-data-container {
  width: 90%;
  min-height: 500px;
  margin: 30px auto 50px auto;
  background: #fff;
  position: relative;
  padding-top: 30px;
  border-radius: 1px;
  box-shadow: 5px 10px 2px 0px black;
  border: 3px solid black;
}

.heroes-name-container {
  width: 100%;
  min-height: 40px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: -170px;
}

.heroes-img-collection {
  width: 100%;
  /* background: #000; */
}

.heroes-video {
  @apply cursor-pointer;
  width: 90%;
  height: 450px;
  margin: 50px auto;
}

.heroes-video iframe {
  @apply cursor-pointer;
}

.meetheroes-slider-right-arrow {
  right: -28px;
}

/* New Style */

.heroName-container {
  width: 80%;
  max-height: 200px;
  /* background: #000; */
  margin: 10px auto 0px auto;
  /* display: flex; */
  text-align: center;
}

.heroName {
  @apply cursor-pointer capitalize text-center;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  font-size: 25px;
  margin: 5px;
}

.hero-dropdown-nameContainer {
  display: none;
}

/* Mobile Responsive */

@media screen and (max-width: 600px) {
  .hero-dropdown-nameContainer {
    justify-content: center;
    margin: 10px auto 0px auto;
    display: flex;
    background: #fff;
    align-items: center;
    width: 70%;
    height: 50px;
    border: 3px solid black;
  }

  .namedrop-down {
    padding-left: 30px;
    width: 100%;
    height: 100%;
    background: transparent;
    text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
      -1px -1px 0 #000;
    color: #fff;
    font-family: "Acme";
    font-size: 15px;
    word-spacing: 2px;
    border: 0px;
    /* margin-top: 5px; */
    border-radius: 2px;
    position: relative;
  }

  .namedrop-down:focus-visible {
    outline: 0;
  }

  .hrn-dropdown-icon {
    text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
      -1px -1px 0 #000;
    color: #fff;
    right: 100px;
    position: absolute;
  }

  .heroName-container {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .meetHeroes-container {
    margin: 0 !important;
  }

  .meetHeroes-main-img {
    width: 90%;
  }

  .heroes-data-container {
    width: 95%;
    margin: 30px auto 30px auto;
    min-height: 400px;
  }

  .heroes-video {
    height: 195px;
  }

  .page-title {
    text-align: center;
    font-size: 30px;
  }
}

@media screen and (max-width: 1100px) {
  /* .meetHeroes-container {
    margin-left: 245px;
  } */

  .heroes-name-container li {
    display: flex;
  }
}

@media screen and (max-width:1024px) {
  .meetHeroes-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .meetHeroes-container {
    margin-left: 315px;
  }
}