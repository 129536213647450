.charcter-img {
  width: 128px;
  height: 253px;
  position: absolute;
  bottom: 15px;
}

.dailyChalleng-box {
  @apply flex;
  background-image: url("../../../../../../public/images/DailyChallenge_box.png");
  width: 100%;
  position: relative;
  margin-bottom: 0.6rem;
  background-size: 100% 100%;
  padding: 6px 5px 7px 10px;
}

.charcter-name {
  @apply w-full;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: 27px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myheroes-container {
  position: absolute;
  width: 106px;
  height: 37px;
  top: 150px;
  left: 40px;
  cursor: pointer;
}

.myheroes-img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.myheroes-text {
  text-shadow: -2px 2px 2px #000, 2px 3px 2px #000, 2px -2px 0 #000,
    -2px -2px 0 #000;
  color: #fff;
  /* -webkit-text-fill-color: white; */
  font-family: "Brady Bunch Remastered";
  /* font-family: 'Acme'; */
  font-size: 24px;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  /* bottom: 8px; */
  letter-spacing: 2px;
}

.skill-dev-btn {
  @apply relative top-0.5 text-xl  text-black px-3 hover:text-red-600 transition-colors;
  white-space: nowrap;
}
