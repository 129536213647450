.friends-name-search {
    width: 90%;
    padding: 3px 0px;
    /* text-shadow: 1px 1px 2px #000; */
    color: silver;
    font-family: 'Brady Bunch Remastered';
    font-size: 18px;
    /* text-transform: uppercase; */
    border: 0;
    -webkit-clip-path: polygon(0 1%, 100% 0%, 97% 100%, 0 100%);
    clip-path: polygon(0 1%, 100% 0%, 97% 100%, 0 100%);
}

input[type=text] {
    padding-left: 10px;
}

.login-to-heroes-text {
    font-family: 'Acme';
    font-size: 13px;
    margin-top: 10px;
}

.friends-list-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 2px solid red; */
    /* background-color: #000; */
    /* margin: 0 auto; */
    position: absolute;
    bottom: 60px;
    left: 5px;
    /* -webkit-clip-path: polygon(0 0, 100% 0%, 9% 100%, 0% 100%); */
    clip-path: polygon(0 0, 95% 0%, 87% 100%, 0% 100%);

}

.friends-list-container::-webkit-scrollbar {
    width: 0px;
}

.friends-name-container {
    color: #000;
    width: 100%;
    background-color: #fff;
    margin: 5px;
    padding: 5px;
    border: 2px solid black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.friends-status {
    width: 15px;
    height: 15px;
    border-radius: 100px;
}

.fr-name {
    font-family: 'Acme';
    font-style: 20px;
    padding-left: 10px;
}

.myfriends-container {
    position: absolute;
    width: 106px;
    height: 37px;
    top: 193px;
    left: 40px;
    cursor: pointer;
}