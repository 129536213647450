.gamesPg-container {
  background-image: url("../../../../public/images/GamesPageBG.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
}

.gamesContentContainer {
  width: 95%;
  height: auto;
  background: #00a0c7;
  margin: 0px auto 50px auto;
  border: 5px solid #fff;
  padding: 10px 30px;
}

.gamesDataContainer {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.gameIcon {
  @apply cursor-pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.gameTitle {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  font-size: 18px;
  text-transform: uppercase;
  /* padding: 20px 30px; */
}

.games-pagintaion {
  width: 90%;
  /* background: #000; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px auto;
}

/* .gameIcon:first-child{
    margin-left: 0;
} */

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .gamesPg-container {
    margin: 0 !important;
  }

  .gamesContentContainer {
    /* height: 100%; */
  }
}

/* @media screen and (max-width: 1100px) {
  .gamesPg-container {
    margin-left: 245px;
  }
} */
@media screen and (max-width: 1024px) {
  .gamesPg-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .gamesPg-container {
    margin-left: 315px;
  }
}

@media screen and (min-width: 1700px) {
  .gamesContentContainer {
    min-height: 10vh;
  }
}
