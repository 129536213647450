@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Acme&family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,400&display=swap");
@font-face {
  font-family: "Brady Bunch Remastered";
  src: url("../public/fonts/BradyBunchRemastered.woff2") format("woff2"),
    url("../public/fonts/BradyBunchRemastered.woff") format("woff"),
    url("../public/fonts/BradyBunchRemastered.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  cursor: url("../public/images/cursor-normal.webp"),
    url("../public/images/cursor-normal.png"), auto !important;
  background-color: #0184ba;
}

textarea,
input {
  cursor: url("../public/images/cursor-text.webp"),
    url("../public/images/cursor-text.png"), text !important;
}

.seasonPassCard,
.read-more-btn-container,
.seasonPs-main-img,
.blog-left-arrow,
.blog-right-arrow,
.blog-box,
a,
button {
  cursor: url("../public/images/cursor-pointer.webp"),
    url("../public/images/cursor-pointer.png"), pointer !important;
}

#root {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 1370px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 20px;
  }
  .head-container {
    display: flex;
    justify-content: center !important;
  }
  .seasonPs-item-title {
    font-size: 32px !important;
  }
  .seasonPs-item-date {
    font-size: 32px !important;
    line-height: 1.3 !important;
  }
  .seasonPs-date {
    font-size: 32px !important;
  }
  .rank-btn {
    font-size: 23px !important;
    width: 230px !important;
  }
  .newspg-dscrp {
    font-size: 30px !important;
  }
  .seasonPs-descrp {
    font-size: 22px !important;
  }
  .season-title {
    font-size: 40px !important;
  }
  .mobile-header {
    flex-direction: row-reverse;
  }

  .mobile-menu-icon-container {
    flex-direction: row-reverse;
  }
  .hamburger-container {
    justify-content: flex-start !important;
  }
  .season-box {
    min-height: initial !important;
  }
  .user-wrap {
    margin-top: 50px !important;
  }
  .season-description {
    font-size: clamp(1.4rem, 2.2vw, 1.8rem) !important;
  }
  .newspg-main {
    flex-direction: column;
  }
  .newspg-main {
    text-align: center;
  }
  li a {
    font-size: 40px !important;
  }
  .bg-blue-700\/60 {
    position: fixed;
  }
  .eventsData-container {
    display: block !important;
  }
  .event-date {
    font-size: 22px !important;
  }
  .seasonPs-newTag {
    font-size: 36px !important;
    margin-left: -7px !important;
    left: 9px !important;
    top: -59px !important;
  }
}

@media screen and (max-width: 768px) {
  .event-descrp {
    font-size: 30px !important;
  }
  .event-title {
    font-size: 45px !important;
  }
  .event-date {
    font-size: 29px !important;
  }

  .blog-description {
    font-size: 1.2rem !important;
  }
  .blog-description {
    display: block !important;
  }
  .blog-date {
    font-size: 18px !important;
  }
  .seasonPs-descrp {
    font-size: 30px !important;
  }
  .season-title {
    font-size: 34px !important;
  }
  .rank-btn {
    font-size: 27px !important;
    width: 254px !important;
  }
}
