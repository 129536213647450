.leaderBoard-container {
  background-image: url("../../../../public/images/pages-bg.jpg");
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
  padding-left: 15px;
}

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .leaderBoard-container {
    margin: 0 !important;
  }
}

/* @media screen and (max-width: 1100px) {
  .leaderBoard-container {
    margin-left: 245px;
  }
} */
@media screen and (max-width: 1024px) {
  .leaderBoard-container {
    margin-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .leaderBoard-container {
    margin-left: 315px;
  }
}
