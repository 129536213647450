.container-rank {
  background: #191919;
  padding: 1rem 2.5rem;
}

input::placeholder {
  font-family: "OlifantW00-Normal";
}

input {
  font-family: "Acme";
  font-weight: bold;
}

.data-row {
  border: 2px solid #00ff0a;
  margin-bottom: 20px;
  font-family: "OlifantW00-Normal";
}

.tab-title {
  margin: 0;
  font-weight: bold;
  font-family: "OlifantW00-Normal";
  font-size: 1.8rem;
  margin-bottom: 0;
  color: black;
}

.col-title {
  color: rgb(238, 238, 238);
  font-weight: bold;
  font-size: 1.2rem;
}

.search-box-container {
  display: flex;
}

.input-box {
  height: 2.4rem;
  border: 0;
  font-size: 1.37rem;
  padding-left: 5px;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0;
  color: black;
}

.input-box:focus-visible {
  outline: solid 2px yellow;
}

.data-row p {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  margin: 0px !important;
}

.data-rank {
  padding-left: 15px;
}

.c-row.data-row {
  padding: 0.8rem 0px;
}

.c-col-30 {
  flex: 30 1;
  display: flex;
  align-items: center;
}

.c-col-60 {
  flex: 60 1;
  display: flex;
  align-items: center;
}

.c-row {
  display: flex;
}

.c-col-10 {
  flex: 10;
  width: 100%;
  display: flex;
  align-items: center;
}

.c-col-70 {
  flex: 70;
  width: 100%;
  display: flex;
  align-items: center;
}

.c-col-20 {
  flex: 20;
  width: 100%;
  display: flex;
  align-items: center;
}
.c-col-80 {
  flex: 80%;
  width: 100%;
}

.c-col-100 {
  flex: 100;
  flex: 1;
}

.rank-btn {
  background-color: #5ea90e;
  padding: 11px 8px;
  border-radius: 5px;
  text-decoration: auto;
  color: black;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.rank-btn:hover {
  color: rgb(25, 25, 25);
}

.tab-title-container {
  background: #eee;
  border-bottom: 2px solid #d3d3d3;
  padding: 0.62rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-btn {
  background-color: #f4d11f;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #000;
  font-weight: 700;
  padding: 8px 10px;
  text-align: center;
  -webkit-text-decoration: auto;
  text-decoration: auto;
  width: 100%;
  line-height: normal;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  width: 100%;
  height: 40vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.tab-data {
  height: 40vh;
  overflow-y: auto;
}

.c-col-10 {
  align-items: stretch;
}

::-webkit-scrollbar {
  width: 15px;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: url("https://heroesleaguegameday.com/wp-content/uploads/2023/02/HL_UI-scrollHandle.png") !important;
  background-size: cover !important;
  width: 18px !important;
  height: 18px !important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  background: url("https://heroesleaguegameday.com/wp-content/uploads/2023/02/scrollbar.png") !important;
  background-repeat: no-repeat !important;
  top: 0px !important;
  background-repeat: no-repeat !important;
  height: 100% !important;
  background-size: contain !important;
  text-align: center;
  width: 19px !important;
  background-position-x: center !important;
}

.ScrollbarsCustom-Scroller {
  margin-right: 0px !important;
}

.ScrollbarsCustom-Wrapper {
  width: 98%;
}

@font-face {
  font-family: "OlifantW00-Normal";
  src: url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.eot");
  src: url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/030ff7f43dec5bbb2c99976c7acdd7b1.svg#OlifantW00-Normal")
      format("svg");
}
