.home-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
}

.row {
  margin: 20px auto;
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.season-box {
  width: 33vw;
  min-height: 250px;
  background: #009fc78c;
  padding: 10px;
  position: relative;
}

.season-description {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  font-size: clamp(1.25rem, 2.2vw, 1.8rem);
  padding-left: 10px;
}

.season-heading {
  font-size: clamp(2.2rem, 2.5vw, 3rem);
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  text-transform: uppercase;
  font-weight: normal;
}

.btn {
  @apply transition-transform duration-200;
  display: flex;
  width: 14.5rem;
  height: 4.8rem;
  background-image: url("../../../../public/images/btns/Btn-Reg_Green-default.svg");
  background-position: 0 0;
  background-size: 100% 100%;
  padding-bottom: 5px;
  text-decoration: none;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: clamp(2.2rem, 2.5vw, 3rem);
  text-transform: uppercase;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  font-family: "Brady Bunch Remastered";
  transition: all 0.8s;
}

.btn:hover {
  background-image: url("../../../../public/images/btns/Btn-Reg_Green-hover.svg");
}

.blog-row {
  width: 95%;
  height: 15rem;
  position: absolute;
  bottom: 1.2rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.heading-btn-container {
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latest-blogs-heading {
  font-family: "Brady Bunch Remastered";
  font-size: 3.2rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: white;
  text-outline: 2px solid #000000;
  -webkit-text-stroke: 2px #000000;
  letter-spacing: 0px;
}

.gift-wrap {
  max-width: 16vw;
  background: #73dd51;
  padding: 15px;
  position: relative;
  box-shadow: 5px 10px 2px 0px black;
  border: 3px solid black;
  width: 100%;
}

.gift-wrap p {
  background-color: #fff;
  color: rgb(16, 19, 20);
  padding: 10px;
  font-family: "Acme";
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  text-align: center;
}

.gift-wrap .gift {
  position: absolute;
  top: -3.2rem;
  left: -2rem;
  max-width: 6rem;
  width: 100%;
}

.gift-wrap .close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 3vw;
  max-width: 50px;
  min-width: 40px;
  cursor: pointer;
}

.frame-body {
  padding-bottom: 1.6rem;
}

/* Mobile Responsiveness */

@media screen and (max-height: 400px) {
  .home-container {
    min-height: 700px;
  }
  .latest-blogs-heading {
    width: 380px !important;
  }
}

@media screen and (max-width: 580px) {
  .home-container {
    /* overflow-x: scroll; */
    min-height: 750px;
  }
}

@media screen and (max-width: 580px) {
  .heading-btn-container {
    top: -38px !important;
  }

  .latest-blogs-heading {
    width: 185px !important;
    font-size: 25px;
    line-height: 1.2;
  }

  .season-box {
    /* height: 330px !important; */
  }
}

@media screen and (max-width: 1024px) {
  .gift-wrap {
    width: 28vw;
    max-width: unset;
    margin: 0 auto;
    margin-top: 10%;
  }

  .blog-row,
  .heading-btn-container,
  .blog-container {
    position: static;
  }
  .blog-row {
    height: auto;
    margin-bottom: 2rem;
  }
  .home-container {
    margin: 0 !important;
  }

  .row {
    flex-direction: column;
  }

  .season-box {
    width: 100%;
    /* height: 300px; */
  }

  .character-container {
    display: none;
  }

  .heading-btn-container {
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    top: -20px;
  }

  .latest-blogs-heading {
    display: block;
    min-width: 180px;
    /* background: #000; */
  }

  .left-arrow-container {
    left: -15px !important;
  }

  .btn {
    width: 210px;
    height: 70px;
    margin-right: auto;
  }
}

@media screen and (max-width: 1100px) {
  .home-container {
    margin-left: 245px;
  }
}

@media screen and (min-width: 1440px) {
  .home-container {
    margin-left: 315px;
  }
}

@media screen and (max-width: 600px) {
  .gift-wrap {
    width: 90%;
    max-width: unset;
    margin: 0 auto;
    margin-top: 10%;
  }
}

.copy-to-clipboard-btn {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: 1.5rem;
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 4px 20px;
  padding-bottom: 10px;
  margin: 0 auto;
  background-image: url("../../../../public/images/btns/Btn-Reg_Orange-default.svg");
  transition: all 0.2s;
}

.copy-to-clipboard-btn:hover {
  background-image: url("../../../../public/images/btns/Btn-Reg_Orange-hover.svg");
}

.close {
  @apply w-12 h-12 absolute -right-6 -top-6;
  background: url("../../../../public/images/close.png");
  background-size: 100% 100%;
}
