.reset-password-container {
    width: 100%;
    min-height: 400px;
    border: 12px solid #002356;
    background-color: #fff;
    padding: 5px 30px;
    position: relative;
  }
  
  .back-button {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -35px;
    left: -25px;
  }
  
  .reset-form-heading {
    font-family: "Brady Bunch Remastered";
    font-size: 60px;
    color: #002356;
    line-height: 3.6rem;
  }
  
  /* Mobile Responsiveness */
  
  @media screen and (max-width: 400px) {
    .reset-form-heading {
      font-size: 37px !important;
    }
  }
  
  @media screen and (max-width: 500px) {
    .reset-form-heading {
      font-size: 50px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .reset-links-container {
      margin-top: 40px;
    }
  }
  @media screen and (min-width: 1500px) {
    .reset-form-heading {
      font-size: 80px;
    }
    .reset-links-container {
      margin-top: 80px;
    }
  }
  