.user-wrap {
  flex: 1 0 0;
  width: 24vw;
  max-width: 450px;
}
.character-container {
  width: 24vw;
  max-width: 350px;
  margin-left: auto;
  /* height: 100%; */
  /* border: 2px solid yellow; */
  position: relative;
}

.chat-tab,
.friends-tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: #fff; */
  /* border: 2px solid red; */
  width: 100%;
  height: 90%;
  flex-direction: column;
  /* overflow-x: auto; */
  position: relative;
}

/* .power-select-character {
  width: 216px;
  height: 327px;
  background-image: url("../../../../../public/images/PowerSelect_characterbox.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
} */

.feedback-btn {
  @apply transition-transform duration-200;
  background-color: #17a2b8;
  text-shadow: -2px 2px 2px #000, 2px 3px 2px #000, 2px -2px 0 #000,
    -2px -2px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  text-transform: uppercase;
  font-size: 30px;
  border: 4px solid black;
  text-align: center;
  box-shadow: #00000075 0px 0px 20px 0px;
  cursor: pointer;
  z-index: 1;
  position: relative;
  width: 70%;
}

.feedback-btn:hover {
  transform: scale(0.9);
}

@media screen and (max-width: 1024px) {
  .user-wrap {
    width: 44vw;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .user-wrap {
    width: 90%;
    margin: 0 auto;
  }
}
