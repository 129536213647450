.read-more-btn-container {
  background-image: url("../../../../public/images/btns/Btn-Reg_Orange-default.svg");
  width: clamp(145px, 9vw, 200px);
  height: clamp(48px, 5vw, 50px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  padding-bottom: 5px;
  transition: all 0.8s;
  margin-left: 15px;
}

.read-more-btn-container:hover {
  background-image: url("../../../../public/images/btns/Btn-Reg_Orange-hover.svg");
}

.read-more-btntext {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: clamp(1.68rem, 2vw, 2rem);
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}
