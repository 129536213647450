.sidebar-container {
  background: url("../../../public/images/sidebar-bg.png");
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  width: 250px;
  height: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  position: fixed;
  z-index: 10;
  overflow: hidden;
  transition: all 0.8s;
}

.logo-nav-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sidebar-footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
}

.logo-container {
  width: clamp(100px, 9vw, 150px);

  /* width: 150px; */
  /* height: 140px; */
  /* background: red; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.logo-img {
  width: 100%;
  height: 100%;
}

.meet-heroes-img-container {
  height: 11rem;
  background-size: 100% 100%;
}

.meet-heroes-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.portal-container {
  width: 100%;
  height: 63px;
  background-image: url("../../../public/images/btns/Btn-Reg_Orange-default.svg");
  background-size: 100% 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s;
}

.portal-container:hover {
  background-image: url("../../../public/images/btns/Btn-Reg_Orange-hover.svg");
}

.portal-container {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Brady Bunch Remastered";
  font-size: clamp(1.5rem, 2vw, 1.5rem);
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
}

.portal-img {
  width: 100%;
  height: 100%;
}

.logout-link {
  text-shadow: -1px 1px 0 #000, 1px 3px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: red;
  font-family: "Brady Bunch Remastered";
  font-size: clamp(1.8rem, 2vw, 2rem);
}

.logout-link:hover {
  color: rgb(199, 0, 0);
}

.mobile_sidebar_container {
  display: none;
}
/* Responsiveness */

@media screen and (max-height: 400px) {
  .logo-nav-container {
    height: 100%;
    margin-top: 300px;
  }
  .sidebar-footer-container {
    margin-top: 30px;
  }
}

@media screen and (max-width: 580px) {
  .sidebar-container {
    width: 180px !important;
  }
  .logo-container {
    width: 110px !important;
    height: 119px;
  }
  .meet-heroes-img-container {
    width: 95%;
  }
}

@media screen and (max-width: 1024px) {
  .mobile_sidebar-container {
    position: relative;
    /* z-index: 7; */
    display: none;
  }
  .sidebar-container {
    position: fixed;
    z-index: 99;
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  .sidebar-container {
    width: 245px;
  }

  .logo-container {
    width: 130px;
    height: 119px;
  }
}

@media screen and (min-width: 1440px) {
  .sidebar-container {
    width: 315px;
  }

  .sidebar-footer-container {
  }

  .meet-heroes-img-container {
    margin-top: 10px;
  }

  /* .logo-container {
    width: 180px;
    height: 167px;
  } */

  .portal-container {
    height: 75px;
    margin-top: 10px;
    /* font-size: 23px; */
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  .logo-container {
    width: 8vw;
    height: auto;
  }
}
