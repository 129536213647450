.comics-container {
  background-image: url("../../../../public/images/comics-bg.jpg");
  /* background: #00a0c7; */
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
}

.comics-data-container {
  width: 95%;
  /* height: 50vh; */
  background: #00a0c7;
  margin: 0px auto 50px auto;
  border: 5px solid #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
  padding: 0px 30px 10px 30px;
}

.new-tag {
  width: 100px;
  height: 40px;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #18ff00;
  font-family: "Acme";
  font-size: 25px;
  text-transform: uppercase;
  transform: rotate(-20deg);
  margin-left: -20px;
  font-weight: 400;
}

.comics-card-container {
  display: flex;
  justify-content: center;
}
.comics-coverimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.readmore-comics-btn {
  margin-left: auto;
  /* margin-right: 10px; */
  margin-top: 20px;
}

.comics-card {
  width: 330px;
  height: 380px;
  /* background: #000; */
  margin: 0px 10px;
}

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .comics-container {
    margin: 0 !important;
  }

  .page-title {
    text-align: center;
    font-size: 60px;
  }
  .comics-data-container {
    align-items: center;
  }

  .comics-card-container {
    flex-direction: column;
    align-items: center;
  }
  .comics-card {
    margin: 10px 0px;
  }
  .readmore-comics-btn {
    margin: 0;
  }
  .new-tag {
    margin-left: -220px;
    height: 30px;
  }
}

/* @media screen and (max-width: 1100px) {
  .comics-container {
    margin-left: 245px;
  }
} */

@media screen and (max-width: 1024px) {
  .comics-container {
    margin-left: 0;
  }
  .comics-card {
    height: 535px !important;
    width: 380px;
  }
  .new-tag {
    margin-left: -290px;
    height: 47px;
  }
}

@media screen and (min-width: 1440px) {
  .comics-container {
    margin-left: 315px;
  }
  .comics-data-container {
    height: 80vh;
  }
  .comics-card {
    width: 470px;
    height: 100%;
  }
  .comics-coverimg {
    width: 90%;
  }
  .readmore-comics-btn {
    margin-top: 40px;
  }
}
