* {
  font-family: "Brady Bunch Remastered";
}

.contact-container {
  background: #ffffff;
  padding: 7px;
}

.contact-tips {
  padding: 10px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-tips h4 {
  font-size: 1rem;
  margin: 0 0 5px 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000000;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.contact-box {
  height: unset;
  text-align: center;
  padding: 10px;
}

@media screen and (max-width: 1650px) {
  .send_help_msg {
    width: 15rem;
  }
}

@media screen and (max-width: 1850px) {
  .send_help_msg {
    width: 17rem;
  }
}

.send_help_msg {
  width: 19rem;
  height: auto;
}

.modal-container {
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #0e0000b0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
