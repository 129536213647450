.newsPage-container {
  background-image: url("../../../../public/images/pages-bg.jpg");
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  color: #fff;
  position: relative;
  margin-left: 250px;
  overflow: auto;
}

.newspg-main {
  width: 100%;
  min-height: 80vh;
  display: flex;
  padding-right: 10px;
  overflow: hidden;
}

.newspg-content-container {
  width: 840px;
  background: #00a0c7;
  margin: 0px 0px 0px 20px;
  border: 5px solid #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 22;
}

.newspg-main-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.newspg-description-container {
  margin-top: 20px;
}

.newspg-dscrp {
  font-family: "Acme";
  color: #fff;
  font-size: 17px;
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  word-spacing: 2.5px;
  margin-bottom: 10px;
}

.moreNews-sidebar-container {
  width: 300px;
  height: 555px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
  z-index: 21;
  margin-left: -10px;
  margin-top: 30px;
  position: relative;
}

.moreNews-topIcon {
  position: absolute;
  top: -15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(85deg);
  z-index: 22;
  cursor: pointer;
}

.moreNews-backIcon {
  position: absolute;
  bottom: -15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(-95deg);
  z-index: 22;
  cursor: pointer;
}

.moreNews-sidebar-img {
  width: 99%;
  height: 99%;
  position: absolute;
  /* box-shadow: 5px 5px 2px 0px black; */
}

.moreNews-listContainer {
  position: relative;
  top: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;
}

.moreNewsitemContainer {
  width: 100%;
  position: absolute;
  text-align: center;
}

.moreNewsitemContainer::-webkit-scrollbar {
  width: 0px;
}

.morenews-title {
  text-shadow: -1px 1px 2px #000, 1px 3px 2px #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
  color: #fff;
  font-family: "Acme";
  font-size: 30px;
  text-transform: uppercase;
  padding: 20px 30px;
}

.moreNews-item {
  width: 95% !important;
  height: 130px;
  margin: 10px auto 30px auto;
  cursor: pointer;
}

.moreNewsImg {
  width: 100%;
  border: 3px solid black;
  margin-bottom: 10px;
}

.mobile-moreNews-slider {
  display: none;
}

.slide-btn:disabled {
  opacity: 0.5;
}

.slide-btn {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  background-size: 100% 100% !important;
}

/* Mobile Responsive */
@media screen and (max-width: 800px) {
  .newsPage-container {
    margin: 0 !important;
  }

  .newspg-main {
    flex-direction: column;
  }

  .newspg-content-container {
    width: 100%;
    margin: 0;
  }

  .moreNews-sidebar-container {
    display: none;
  }

  .mobile-moreNews-slider {
    width: 95%;
    height: 200px;
    background-color: #000;
    margin: 50px auto;
    position: relative;
    display: block;
  }

  .moreNews-slider-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 30;
  }

  .moreNews-sidePrevIcon {
    position: absolute;
    top: 35%;
    left: -10px;
    z-index: 31;
  }

  .moreNews-sideNxtIcon {
    position: absolute;
    top: 35%;
    right: -10px;
    transform: rotate(180deg);
    z-index: 31;
  }

  .moblNewsSliderContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background: #000; */
    z-index: 30;
    display: block;
    top: 20px;
    left: 17px;
  }

  .mobileSlide-newsImg {
    width: 90% !important;
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .newsPage-container {
    margin-left: 0px;
  }
  .moreNews-sidebar-img {
    display: none;
  }
  .moreNews-sidebar-container {
    background-color: transparent;
  }
}

@media screen and (min-width: 1440px) {
  .newsPage-container {
    margin-left: 315px;
  }
}

@media screen and (min-width: 1700px) {
  .moreNews-sidebar-container {
    max-height: 940px;
  }

  .newspg-content-container {
    width: 70%;
    margin: 0px 0px 0px 60px;
  }
}
